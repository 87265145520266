import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import "animate.css";
import WOW from "wow.js";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "jquery-match-height";
import Lottie from "lottie-web";

//#region animate.css triggered on viewport
var wow = new WOW({
  boxClass: "wow",
  animateClass: "animated",
  mobile: false,
  offset: 0,
});
wow.init();
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: ".global-container",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion
//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});

$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion
//#region sticky sidebars
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".fixed-share-container", {
    stickTo: "#fixed-share-container",
    top: 175,
  });
});
//#endregion

//#region slider
$(function () {
  $(".slider").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
  });
});
//#endregion
//#region animazioni icone
const animazioni = document.querySelectorAll(".lottie-custom-animation");
animazioni.forEach((element) => {
  const json = element.dataset.json;
  Lottie.loadAnimation({
    container: element,
    renderer: "svg",
    loop: true,
    autoplay: true,
    path: window["root"] + "/animations/" + json + ".json",
  });
});

//#endregion
//#region gallery

Fancybox.bind('[data-fancybox="gallery"]', {});

//#endregion

//#region class on active nav link
document.addEventListener("DOMContentLoaded", function () {
  const links = document.querySelectorAll(".nav-item .nav-link");

  links.forEach((link) => {
    let slug = link.getAttribute("href").split("/").reverse()[0];
    if (window.location.href.indexOf(slug) > -1) {
      link.classList.add("active");
    }

    if (window.location.href === link.getAttribute("href")) {
      link.classList.add("active");
    }
  });
});

//#endregion
$(document).ready(function () {
  $(".modal-temp").modal("show");
});

//prova per form
document.addEventListener("DOMContentLoaded", function() {
  const formContatti = document.getElementById("formContatti");
  const confirmBtn = document.getElementById("confirmBtn");

  if (formContatti != null && confirmBtn != null) {
    confirmBtn.addEventListener("click", function(event) {
        console.log("funzione!!");

        event.preventDefault();
        grecaptcha.execute();
        if (checkCampi()) {
            console.log('formcontatti.submit');
            formContatti.submit();
        }
    });
  }

  const inputs = document.querySelectorAll("#formContatti input[type='text'], #formContatti textarea, #formContatti input[type='checkbox']");
  inputs.forEach(function(input) {
      input.addEventListener("change", function() {
          checkCampi();
      });
  });
});

function checkCampi() {
  console.log("Inizio - checkcampi()");
  const labelAlert = document.getElementById("alert-message");
  const formFields = ["name", "email", "messaggio", "privacy"];
  let isValid = true;

  formFields.forEach(function(fieldId) {
      const fieldValue = document.getElementById(fieldId).value;
      console.log(fieldValue);
      if (fieldValue == null || fieldValue.trim() == "") {
          labelAlert.style.display = "inline";
          isValid = false;
      }
  });

  if (isValid) {
      labelAlert.style.display = "none";
  }

  return isValid;
}